// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/emarchak/Sites/github.com/emarchak/pbp2019/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/emarchak/Sites/github.com/emarchak/pbp2019/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pbp-index-js": () => import("/Users/emarchak/Sites/github.com/emarchak/pbp2019/src/pages/pbp/index.js" /* webpackChunkName: "component---src-pages-pbp-index-js" */),
  "component---src-pages-rides-index-js": () => import("/Users/emarchak/Sites/github.com/emarchak/pbp2019/src/pages/rides/index.js" /* webpackChunkName: "component---src-pages-rides-index-js" */),
  "component---src-pages-rides-paris-brest-paris-2019-index-js": () => import("/Users/emarchak/Sites/github.com/emarchak/pbp2019/src/pages/rides/paris-brest-paris-2019/index.js" /* webpackChunkName: "component---src-pages-rides-paris-brest-paris-2019-index-js" */)
}

